import React, { FC, memo } from "react";
import { Button } from "components/shared";
import { Download } from "assets/icons";
import templateFile from "assets/files/template.xlsx";

interface IProps {
    onUploadClick: () => void;
    onCloseClick: () => void;
}

export const TemplateUploader: FC<IProps> = memo(
    ({ onUploadClick, onCloseClick }) => (
        <div className="flex flex-col items-center">
            <a
                className="mb-8 text-primary-main-gray hover:text-primary-main-dgray flex items-center"
                href={templateFile}
                download="formula_template.xlsx"
            >
                <Download className="mr-2" />
                Download Template (Excel)
            </a>
            <div className="flex items-center">
                <Button
                    text="Upload Formula"
                    className="btn btn-primary hover:btn-primary mr-2"
                    onClick={onUploadClick}
                />
                <Button text="Close" onClick={onCloseClick} />
            </div>
        </div>
    ),
);
