export const TabHeader = {
    BASIC_INFO: "Basic Information",
    SAFETY_SUMMARY: "Safety Summary",
    REPORT_SUMMARY: "Report Summary",
    ENVIRONMENTAL_SUMMARY: "Environmental Summary",
    SAFETY_DATA_SHEETS: "Safety Data Sheets",
    SKIN_SAFETY: "Skin Safety",
} as const;

export const tabHeaders = Object.values(TabHeader);
