import React, { useMemo, useState } from "react";
import { IOption } from "common/types";
import { MultiValue } from "react-select";
import { subIngredientService } from "services";
import { useSubIngredients } from "pages/subIngredient/subIngredients/queries";
import { AddNewItemTemplate } from "./AddNewItemTemplate";

interface IProps {
    onClose: () => void;
    push: <X = unknown>(obj: X) => void;
    totalSubWeight: number;
}

const defaultSubIngredientData = {
    name: "",
    weight_percent: "",
};

export const AddSubIngredient: React.FC<IProps> = React.memo(
    ({ onClose, push }) => {
        const [selectedSubIngredient, setSelectedSubIngredient] =
            useState<IOption | null>(null);
        const [error, setError] = useState<string | null>(null);

        const { subIngredients } = useSubIngredients();

        const options = useMemo(() => {
            if (subIngredients?.data) {
                return subIngredients?.data?.map((item) => {
                    return {
                        value: String(item?.id),
                        label: `${item?.sicode}, ${item?.name}`,
                    };
                }) as unknown as MultiValue<IOption>;
            } else {
                return [];
            }
        }, [subIngredients?.data]);

        const handleUpdateRelatedList = (newValue: IOption) => {
            setError(null);
            setSelectedSubIngredient(newValue);
        };

        const handleCreate = () => {
            if (selectedSubIngredient) {
                subIngredientService
                    .getSubIngredient({ id: selectedSubIngredient.value })
                    .then((res) => {
                        const newItem = {
                            name: res.name,
                            weight_percent: "",
                        };

                        push(newItem);
                    })
                    .catch(() => {
                        setError("An error occurred! Please try again");
                    });
            } else {
                push(defaultSubIngredientData);
            }

            onClose();
        };

        return (
            <AddNewItemTemplate
                isLoading={subIngredients?.isLoading}
                options={options}
                values={selectedSubIngredient}
                onChange={handleUpdateRelatedList}
                handleCreate={handleCreate}
                onClose={onClose}
                error={error}
            />
        );
    },
);
