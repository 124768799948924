import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { ModalTemplate, Title, DataTable } from "components/shared";
import { useModal } from "common/hooks";
import { TabContentTemplate } from "pages/subIngredient/subIngredientProfile/components";
import { useSDSFiles } from "pages/subIngredient/subIngredientProfile/queries";
import { type SafetyDataSheet } from "common/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilePdf,
    faFileDownload,
    faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

export const SafetyDataSheets = () => {
    const [selectedFile, setSelectedFile] = useState<string>();
    const { modalRef, openModal, closeModal } = useModal();

    const { id = "" } = useParams();

    const { sdsFiles = [], updateSDSFiles, isLoading } = useSDSFiles(id);

    const handlePreviewClick = useCallback((link: string) => {
        setSelectedFile(link);
        openModal();
    }, []);

    const handleUpdateClick = useCallback(() => {
        updateSDSFiles();
    }, []);

    const sdsColumnsConfig: ColumnDef<SafetyDataSheet>[] = useMemo(
        () => [
            {
                accessorKey: "supplier",
                header: () => <span>Supplier</span>,
                enableSorting: false,
            },
            {
                accessorKey: "link",
                header: () => <span>SDS Download url</span>,
                cell: ({ row }) => (
                    <a
                        href={row.original.link}
                        target="_blank"
                        rel="noreferrer noopenner"
                        download
                        className="flex items-center px-2 py-1 space-x-1 text-blue-500 hover:underline"
                    >
                        <FontAwesomeIcon
                            icon={faFileDownload}
                            className="text-blue-500"
                            size="lg"
                        />
                        <span className="text-sm font-medium">Download</span>
                    </a>
                ),
                enableSorting: false,
            },
            {
                accessorKey: "view",
                header: () => <span>View</span>,
                cell: ({ row }) => (
                    <button
                        onClick={() => handlePreviewClick(row.original.link)}
                        className="flex items-center px-2 py-1 space-x-1"
                    >
                        <FontAwesomeIcon
                            className="text-red-400"
                            icon={faFilePdf}
                            size="lg"
                        />
                        <span className="text-sm font-medium text-gray-700">
                            View
                        </span>
                    </button>
                ),
                enableSorting: false,
            },
        ],
        [],
    );

    return (
        <TabContentTemplate>
            <div className="flex justify-between items-center">
                <Title text="Safety Data Sheets" />
                <Button
                    onClick={handleUpdateClick}
                    className={`flex items-center justify-center gap-2 px-4 py-1 h-8 text-white rounded-md transition-colors ${
                        isLoading
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-blue-600 hover:bg-blue-500 hover:text-gray-400"
                    }`}
                    disabled={isLoading}
                >
                    <FontAwesomeIcon
                        icon={faSyncAlt}
                        className="text-base"
                        color={isLoading ? "#9ca3af" : "#34d399"}
                    />
                    {isLoading ? "Refetching..." : "Refetch SDS Files"}
                </Button>
            </div>

            <DataTable
                className="!w-[60%] bg-white rounded-lg shadow-md overflow-x-auto"
                columns={sdsColumnsConfig}
                data={sdsFiles}
            />

            <ModalTemplate
                text="View Details"
                modalRef={modalRef}
                onClose={closeModal}
                className="w-screen h-screen overflow-auto"
            >
                {selectedFile ? (
                    <iframe
                        src={selectedFile}
                        width="100%"
                        height="92%"
                        title="PDF Preview"
                    />
                ) : (
                    <p>No file selected.</p>
                )}
            </ModalTemplate>
        </TabContentTemplate>
    );
};
