import { routerKeys } from "common/constants";

interface IEntityTable {
    headers: string[];
    title: string;
    link: string;
}

interface IEntity {
    formulas: IEntityTable;
    rawIngredients: IEntityTable;
    subIngredients: IEntityTable;
    contaminants: IEntityTable;
}

export const Entity: IEntity = {
    formulas: {
        headers: ["FI Code", "Formula Description", "Formula Status", ""],
        title: "Formulas",
        link: routerKeys.formulas,
    },
    rawIngredients: {
        headers: ["RI Code", "Name", "Status", ""],
        title: "Raw Ingredients",
        link: routerKeys.rawIngredient,
    },
    subIngredients: {
        headers: ["SI Code", "Name", "Function", ""],
        title: "Sub Ingredients",
        link: routerKeys.subIngredient,
    },
    contaminants: {
        headers: ["Name", "Description", ""],
        title: "Contaminants",
        link: routerKeys.contaminants,
    },
};
