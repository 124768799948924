import { TableHeaderCeil } from "components/shared/tableHeaderCeil";
import React, { useMemo } from "react";
import { TabTable } from "../../../TabTable";
import { useGetRawIngredient } from "pages/rawIngredient/rawIngredientProfile/queries";
import { SubIngredientRow } from "./SubIngredientRow";
import { TableCeil } from "components/shared/tableCeil";

export const CompositionTable = () => {
    const rawIngredient = useGetRawIngredient();
    const { subIngredients = [] } = rawIngredient || {};

    const totalAmount =
        useMemo(
            () =>
                subIngredients?.reduce((acc, el) => {
                    const value =
                        +el.rawIngredientsSubIngredients.weight_percent;
                    return acc + value;
                }, 0),
            [rawIngredient],
        ) || 0;

    return (
        <TabTable>
            <thead>
                <tr className="sm:table-row rounded-lg border-b border-primary-main-dgray">
                    <TableHeaderCeil className="w-[16%]" text="SI Code" />
                    <TableHeaderCeil
                        className="w-[16%]"
                        text="Sub Ingredient"
                    />
                    <TableHeaderCeil
                        text="% w/w"
                        className="w-[16%] text-center"
                    />
                    <TableHeaderCeil
                        className="whitespace-nowrap hidden sm:table-cell w-[16%]"
                        text="CAS #"
                    />
                    <TableHeaderCeil
                        className="whitespace-nowrap hidden sm:table-cell w-[16%]"
                        text="SDS Files"
                    />
                    <TableHeaderCeil className="w-[10%]" />
                </tr>
            </thead>
            <tbody>
                {subIngredients?.length ? (
                    subIngredients
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((subIngredient) => (
                            <SubIngredientRow
                                key={`sub-${subIngredient.id}`}
                                subIngredient={subIngredient}
                                totalAmount={totalAmount}
                            />
                        ))
                ) : (
                    <tr>
                        <TableCeil text="No Sub Ingredients found" />
                        {[...Array(4)].map((_, idx) => (
                            <TableCeil key={idx} />
                        ))}
                    </tr>
                )}
                {Boolean(subIngredients?.length) && (
                    <tr>
                        <TableCeil />
                        <TableCeil
                            className="font-bold text-right"
                            text="Total amount:"
                        />
                        <TableCeil
                            className="text-center"
                            text={`${totalAmount}`}
                        />
                        <TableCeil />
                        <TableCeil />
                        <TableCeil />
                    </tr>
                )}
            </tbody>
        </TabTable>
    );
};
