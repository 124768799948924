export const parseIngredients = (
    data: Record<string, string>[],
    headerRowKeys: Record<string, string>,
) => {
    const startIdx = data.findIndex((row) =>
        Object.values(row).includes("Supplier"),
    );
    const keys = {
        supplier: headerRowKeys["Supplier"],
        tradename: headerRowKeys["Trade Name"],
        rawDescription: headerRowKeys["Raw Ingredient"],
        rawWeight: headerRowKeys["% Raw Ingredient"],
        subName: headerRowKeys["Sub Ingredient"],
        casNumber: headerRowKeys["CAS#"],
        subWeight: headerRowKeys["% Sub Ingredient"],
        subAmount: headerRowKeys["% in Final Formulation (by weight)"],
        contaminant: headerRowKeys["Contaminants"],
        // ricode: headerRowKeys["Raw Ingredient Code"],
        // sicode: headerRowKeys["SI Code"],
        // function: headerRowKeys["Function"],
    };
    const rawIngredients = [];

    for (const item of data.slice(startIdx + 1)) {
        const contaminants = parseContaminants(item[keys.contaminant]);

        const subWeightPercent = isNaN(+item[keys.subWeight])
            ? item[keys.subWeight]?.split(" ")[0] || "0"
            : item[keys.subWeight];

        const riDescription = item[keys.rawDescription]?.trim();

        if (riDescription) {
            const rawWeightPercent = isNaN(+item[keys.rawWeight])
                ? item[keys.rawWeight]?.split(" ")[0] || "0"
                : item[keys.rawWeight];

            const vendor = item?.[keys.supplier]?.trim();
            const tradename = item?.[keys.tradename]?.trim();

            const casRegex = /\b\d{2,7}-\d{2}-\d\b/g;
            const casNumber = item[keys.casNumber]
                ?.match(casRegex)
                ?.join(" / ");

            const rawIngredient = {
                ricode: "",
                description: riDescription,
                weight_percent: rawWeightPercent,
                vendor,
                tradename,
                isOwner: false,
                isExist: false,
                contaminants,
                subIngredients: [
                    {
                        name: item[keys.subName],
                        weight_percent: subWeightPercent,
                        cas_number: casNumber,
                    },
                ],
            };
            rawIngredients.push(rawIngredient);
        } else {
            // if raw ingredient has more than one sub-ingredient
            const lastRawIngredient = rawIngredients[rawIngredients.length - 1];
            lastRawIngredient.contaminants = contaminants;

            const siName = item[keys.subName]?.trim();

            if (
                siName &&
                item[keys.subWeight] !== "0" &&
                item[keys.subWeight] !== "" &&
                item[keys.subWeight] !== undefined
            ) {
                lastRawIngredient.subIngredients.push({
                    name: siName,
                    weight_percent: subWeightPercent,
                    cas_number: item[keys.casNumber],
                });
            }
        }
    }

    return {
        description: data?.[0]?.[0]?.split(":")?.[1]?.trim() || "",
        items: rawIngredients,
    };
};

function parseContaminants(text: string) {
    const result = [];
    const contaminantRegex =
        /([a-zA-Z0-9\-\s]+)([<>=]+)([\d\.]+)\s?(ppm|mg\/kg|%)/gi;
    let match;

    while ((match = contaminantRegex.exec(text)) !== null) {
        const [_, contaminant, operator, value, unit] = match;

        const parsedItem = {
            name: contaminant.trim(),
            description: `${operator}${value} ${unit}`,
            ppm: value,
        };

        result.push(parsedItem);
    }

    return result;
}
