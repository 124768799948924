import { routerKeys } from "common/constants";

export const TableStructure = {
    formulas: {
        headers: [
            "FI Code",
            "Formula Description",
            "Formula Status",
            "Created By",
            "Save Item",
            "Edit Item",
        ],
        title: "Formulas",
        link: routerKeys.formulas,
    },
    rawIngredients: {
        headers: ["RI Code", "Name", "Status", ""],
        title: "Raw Ingredients",
        link: routerKeys.rawIngredient,
    },
    subIngredients: {
        headers: ["SI Code", "Name", "Function", ""],
        title: "Sub Ingredients",
        link: routerKeys.subIngredient,
    },
    contaminants: {
        headers: ["Name", "Description", "Created", ""],
        title: "Contaminants",
        link: routerKeys.contaminants,
    },
};
