import React, { FC, memo } from "react";
import { ModalTemplate } from "components/shared/modalTemplate";
import { useFetchSubIngredient } from "../queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useSubIngredientStore } from "../store";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { ISubIngredientResponse } from "../libs/types";
import { UpdateSubIngredientForm } from "pages/subIngredient/subIngredients/components/UpdateSubIngredientForm";
import { useModal } from "common/hooks";

interface IProps {
    refetch: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<ISubIngredientResponse, Error>>;
}

export const Header: FC<IProps> = memo(({ refetch }) => {
    const { data: subIngredient } = useFetchSubIngredient();
    const { isOwner } = useSubIngredientStore();

    const {
        modalRef: updateSubIngredientRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    return (
        <>
            <ModalTemplate
                text="Edit Sub Ingredient"
                onClose={closeModal}
                modalRef={updateSubIngredientRef}
                className="max-w-[600px]"
            >
                {subIngredient && (
                    <UpdateSubIngredientForm
                        handleClose={closeModal}
                        subIngredient={subIngredient}
                    />
                )}
            </ModalTemplate>
            <div className="md:flex lg:flex items-center p-3 mt-10 mx-10 mb-1 justify-between shadow-sm rounded-lg bg-[#EDE9FE] dark:bg-gray-800">
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        {subIngredient?.sicode}
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        <b>{subIngredient?.name}</b>
                    </span>
                </div>
                <div>
                    <span className="text-xs md:text-sm lg:text-base text-gray-800 dark:text-gray-200">
                        {subIngredient?.class}
                        {(isOwner || true) && (
                            <button className="ml-2" onClick={openModal}>
                                <FontAwesomeIcon icon={faPencil} />
                            </button>
                        )}
                    </span>
                </div>
            </div>
        </>
    );
});
