import React, { FC, memo } from "react";
import { toast } from "react-toastify";
import { defaultToastOptions, queryKeys, toastTexts } from "common/constants";
import { Button } from "components/shared/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputWrapper } from "components/shared/inputWrapper";
import { useUpdateSubIngredient } from "../queries";
import { ISubIngredient } from "common/types";
import { useQueryClient } from "@tanstack/react-query";

interface IProps {
    handleClose: () => void;
    subIngredient: ISubIngredient;
}

const UpdateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
});

export const UpdateSubIngredientForm: FC<IProps> = memo(
    ({ handleClose, subIngredient }) => {
        const queryClient = useQueryClient();
        const { mutate: updateSubIngredient } = useUpdateSubIngredient();

        const formik = useFormik({
            initialValues: {
                name: subIngredient.name || "",
            },
            validationSchema: UpdateSchema,
            validateOnChange: true,
            validateOnMount: true,
            enableReinitialize: true,
            onSubmit: (values) => {
                updateSubIngredient(
                    {
                        id: subIngredient.id,
                        body: { name: values.name },
                    },
                    {
                        onSuccess: () => {
                            toast.success(
                                "Success! Sub Ingredient name has been updated",
                                defaultToastOptions,
                            );
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.subIngredients],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.subIngredient],
                            });
                            handleClose();
                        },
                        onError: () => {
                            toast.error(toastTexts.error, defaultToastOptions);
                        },
                    },
                );
            },
        });

        const { values, errors, touched, handleChange, handleSubmit } = formik;

        return (
            <form onSubmit={handleSubmit}>
                <InputWrapper
                    isError={Boolean(errors.name && touched.name)}
                    error={errors.name}
                    label="Name"
                >
                    <textarea
                        className="textarea textarea-bordered w-full flex-grow"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                    />
                </InputWrapper>
                <div className="flex items-center justify-end gap-2 mt-3">
                    <Button text="Cancel" onClick={handleClose} />
                    <Button text="Update" type="submit" />
                </div>
            </form>
        );
    },
);
