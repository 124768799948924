import React from "react";
import { routerKeys } from "common/constants";
import { NavLink } from "./NavLink";

export const NavLinks = () => {
    return (
        <div className="w-full my-6">
            <div className="h-18 flex items-center justify-around rounded-md bg-white">
                <NavLink to={routerKeys.formulas} text="Formulas" />
                <NavLink to={routerKeys.rawIngredient} text="Raw ingredients" />
                <NavLink to={routerKeys.subIngredient} text="Sub Ingredients" />
                <NavLink to={routerKeys.contaminants} text="Contaminants" />
            </div>
        </div>
    );
};
