import { Title } from "components/shared/title";
import React from "react";
import { CompositionTable, TestsTable } from "./components";
import { AddRelatedIngredients } from "../../";
import { useRawIngredientStore } from "pages/rawIngredient/rawIngredientProfile/store";

export const CompositionTab = () => {
    const { isOwner } = useRawIngredientStore();

    return (
        <>
            <div className="flex flex-col md:mx-5 lg:mx-10 gap-4">
                {/* Left section */}
                <div className="w-full">
                    <Title text="Composition & Testing Data" className="mt-2" />
                    {(isOwner || true) && (
                        <AddRelatedIngredients type="Sub Ingredient" />
                    )}
                    <CompositionTable />
                </div>

                {/* Right section */}
                <div className="w-full mb-4">
                    {(isOwner || true) && <div className="h-[48px] my-4" />}
                    <TestsTable />
                </div>
            </div>
        </>
    );
};
