import { keepPreviousData, useQuery, useMutation } from "@tanstack/react-query";
import { queryKeys } from "common/constants";
import { contaminantsService } from "services/contaminantsService";
import { ICreateContaminantBody } from "../types/createContaminantBody";
import { IContaminant, IQuery } from "common/types";

const nameComparator = (a: IContaminant, b: IContaminant) =>
    a.name.localeCompare(b.name);

export const useGetContaminants = ({ queryParams, sorting }: IQuery) => {
    const queryKey = [
        queryKeys.contaminants,
        queryParams,
        `${sorting?.[0]?.id}-${sorting?.[0]?.desc}`,
    ];

    return useQuery({
        queryKey,
        queryFn: () => contaminantsService.getContaminants({ queryParams }),
        select: (data) => ({
            count: data?.count,
            rows: data?.rows?.sort(nameComparator),
        }),
        placeholderData: keepPreviousData,
    });
};

export const useLastContaminants = ({ queryParams }: IQuery) => {
    const queryKey = [queryKeys.contaminants, queryParams];

    return useQuery<IContaminant[]>({
        queryKey,
        queryFn: () =>
            contaminantsService
                .getContaminants({ queryParams })
                .then((res) => res?.rows),
        placeholderData: keepPreviousData,
    });
};

export const useGetContaminantsStatistic = () => {
    const queryKey = [queryKeys.contaminants, queryKeys.statistic];

    return useQuery({
        queryKey,
        queryFn: () => contaminantsService.getContaminantsStatistic(),
    });
};

export const useCreateContaminant = () => {
    return useMutation({
        mutationFn: ({ body }: { body: Partial<ICreateContaminantBody> }) =>
            contaminantsService.createContaminant({ body }),
    });
};

export const useGetAllContaminants = () => {
    return useQuery({
        queryKey: [queryKeys.contaminants, "all"],
        queryFn: () => contaminantsService.getAll(),
    });
};

export const useUpdateContaminant = () => {
    return useMutation({
        mutationFn: ({
            id,
            body,
        }: {
            id: number;
            body: Pick<IContaminant, "description" | "name">;
        }) =>
            contaminantsService.updateContaminant({
                id,
                body,
            }),
    });
};
