import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../button";
import { FilterStatusList } from "../filter-status-list";
import { IFilter } from "common/types";
import classNames from "classnames";

interface IProps {
    isFilterVisible: boolean;
    onFilterVisible: () => void;
    onRemoveFilter: (key: string) => void;
    filters: IFilter;
}

export const FilterBlock: FC<IProps> = ({
    isFilterVisible,
    onFilterVisible,
    onRemoveFilter,
    filters,
}) => {
    return (
        <div className="flex items-center gap-5 mb-4">
            <div>
                {" "}
                <Button
                    className="btn-filter bg-indigo-100 sm:shadow-lg flex items-center"
                    onClick={onFilterVisible}
                >
                    Filters
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        size="sm"
                        className={classNames(
                            "ml-2 transition-transform duration-200",
                            {
                                "rotate-180": isFilterVisible,
                            },
                        )}
                    />
                </Button>
            </div>
            <FilterStatusList
                filters={filters}
                onRemoveFilter={onRemoveFilter}
            />
        </div>
    );
};
