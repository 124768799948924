import { backendKeys } from "common/constants";
import BaseService from "./baseService";
import instanse from "./axios/instance";
import { ISubIngredientsResponse } from "pages/subIngredient/subIngredients/libs/types/subIngredientsResponse";
import {
    IEnvironmentalClass as IEnvironmentalClass,
    IOption,
    IOverallRank,
    IQuery,
    IStatisticResponse,
    ISubIngredient,
    ISubIngredientError,
    SafetyDataSheet,
} from "common/types";
import { ICreateSubIngredientBody } from "pages/subIngredient/subIngredients/libs/types";
import { getSortingParams } from "common/utils";

class SubIngredientsService extends BaseService {
    constructor(
        baseUrl = backendKeys.subIngredients,
        private fetchingService = instanse,
    ) {
        super(baseUrl);
    }

    async getSubIngredients({ queryParams, sorting }: IQuery) {
        const limit = queryParams.includes("limit") ? "" : "&limit=20";
        const sortingParams = getSortingParams(sorting || []);

        return this.handleRequest<ISubIngredientsResponse>(
            this.fetchingService.get(
                this.getFullUrl(`?${queryParams}${limit}${sortingParams}`),
            ),
        );
    }

    async getAll() {
        return this.handleRequest<ISubIngredient[]>(
            this.fetchingService.get(this.getFullUrl("/all")),
        );
    }

    async getSubIngredientsStatistic() {
        return this.handleRequest<IStatisticResponse>(
            this.fetchingService.get(this.getFullUrl("/statistic")),
        );
    }

    async getSubIngredientsOverallRank() {
        return this.handleRequest<IOverallRank[]>(
            this.fetchingService.get(
                this.getFullUrl("/filter/overall_environmental_rank"),
            ),
        );
    }

    async getSubIngredientsEnvironmentalClass() {
        return this.handleRequest<IEnvironmentalClass[]>(
            this.fetchingService.get(
                this.getFullUrl("/filter/environmental_class"),
            ),
        );
    }

    async createSubIngredient({ body }: { body: ICreateSubIngredientBody }) {
        return this.handleRequest<ISubIngredient | ISubIngredientError>(
            this.fetchingService.post(this.getFullUrl("/create"), body),
        );
    }

    async updateSubIngredient({
        id,
        body,
    }: {
        id: number;
        body: Pick<ICreateSubIngredientBody, "name">;
    }) {
        return this.handleRequest<ISubIngredient>(
            this.fetchingService.put(this.getFullUrl(`/${id}`), body),
        );
    }

    async getSubIngredientOptions(field: string) {
        return this.handleRequest<IOption[]>(
            this.fetchingService.get(this.getFullUrl(`/options/${field}`)),
        );
    }

    async getEnvironmentalClassOptions() {
        return this.handleRequest<IOption[]>(
            this.fetchingService.get(this.getFullUrl("/environmental-class")),
        );
    }

    async fetchSimilarItems(name: string) {
        return this.handleRequest<ISubIngredientsResponse>(
            this.fetchingService.get(this.getFullUrl(`/?query=${name}`)),
        );
    }

    async getCrammerClassOptions() {
        return this.handleRequest<IOption[]>(
            this.fetchingService.get(this.getFullUrl("/cramer-class")),
        );
    }

    async getFunctionOptions() {
        return this.handleRequest<IOption[]>(
            this.fetchingService.get(this.getFullUrl("/function")),
        );
    }

    async getSDSFiles(id: string) {
        return this.handleRequest<SafetyDataSheet[]>(
            this.fetchingService.get(this.getFullUrl(`/${id}/sds-files`)),
        );
    }

    async updateSDSFiles(id: string) {
        return this.handleRequest<SafetyDataSheet[]>(
            this.fetchingService.put(this.getFullUrl(`/${id}/sds-files`)),
        );
    }
}

export const subIngredientsService = new SubIngredientsService();
