import classNames from "classnames";
import React, { useState } from "react";
import { RawIngredients, SubIngredients } from "./tabs";

type SelectedTab = "RawIngredients" | "SubIngredients";

const tabs: SelectedTab[] = ["RawIngredients", "SubIngredients"];

type ContentMap = {
    [key in SelectedTab]: React.ReactNode;
};

export const Content = () => {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const ContentMap: ContentMap = {
        RawIngredients: <RawIngredients />,
        SubIngredients: <SubIngredients />,
    };

    return (
        <div className="mx-10 my-10">
            <div className="tabs bg-white tabs-lifted flex">
                {tabs.map((tab) => (
                    <a
                        key={tab}
                        className={classNames("tab", {
                            "tab-active": selectedTab === tab,
                        })}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </a>
                ))}
            </div>
            {ContentMap[selectedTab]}
        </div>
    );
};
