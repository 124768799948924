import React, { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button, ModalTemplate } from "components/shared";
import { CreateINCIForm } from "pages/subIngredient/subIngredients/components";
import { CreateContaminantForm } from "pages/contaminant/contaminants/components";
import { useGetRawIngredient, useUploadRawIngredient } from "../queries";
import { useModal } from "common/hooks";

type tabType = "Sub Ingredient" | "Contaminant";

interface IProps {
    type: tabType;
}

export const AddRelatedIngredients: FC<IProps> = ({ type }) => {
    const { id = "" } = useParams();
    const { refetch } = useUploadRawIngredient({ id });
    const rawIngredient = useGetRawIngredient();

    const totalAmount =
        useMemo(
            () =>
                rawIngredient?.subIngredients?.reduce((acc, el) => {
                    const value =
                        +el.rawIngredientsSubIngredients.weight_percent;
                    return acc + value;
                }, 0),
            [rawIngredient],
        ) || 0;

    const {
        modalRef: createIngredientsRef,
        openModal,
        closeModal,
    } = useModal({
        onClose: () => {
            refetch();
        },
    });

    const ContentMap = {
        "Sub Ingredient": (
            <CreateINCIForm
                handleClose={closeModal}
                raw_ingredients_id={id}
                maxValue={100 - totalAmount}
            />
        ),
        Contaminant: (
            <CreateContaminantForm
                handleClose={closeModal}
                raw_ingredients_id={id}
            />
        ),
    };

    return (
        <div className="w-full flex my-4">
            <Button text={`Add ${type}`} onClick={openModal} />
            <ModalTemplate
                text={`Add ${type}`}
                onClose={closeModal}
                modalRef={createIngredientsRef}
                className="max-w-[600px]"
            >
                {ContentMap[type]}
            </ModalTemplate>
        </div>
    );
};
