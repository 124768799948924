import React, { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { useCreateFormulaBulk } from "pages/formulas/formulas/queries";
import { InputWrapper, Button } from "components/shared";
import { IParsedFormula } from "pages/formulas/formulas/types";
import { RawIngredientItem } from "./RawIngredientItem/RawIngredientItem";
import { validationSchema } from "../schema";
import BigNumber from "bignumber.js";
import { AddRawIngredient } from "../AddRawIngredient";
import {
    defaultSuccessToastUpdate,
    defaultErrorToastUpdate,
    defaultToastOptions,
    queryKeys,
    toastTexts,
    StatusCode,
} from "common/constants";
import { useFormulaStore } from "pages/formulas/formulas/store";
import { AxiosError } from "axios";
import { IError } from "common/types";
import classNames from "classnames";

interface IProps {
    initialValues: IParsedFormula;
    onClose: () => void;
}

interface IErrors extends IParsedFormula {
    totalRawWeight: string;
}

export const UploadFormulaForm: FC<IProps> = ({ initialValues, onClose }) => {
    const [totalRawWeight, setTotalRawWeight] = useState(0);
    const [isVisibleCreateDialog, setIsVisibleCreateDialog] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const queryClient = useQueryClient();
    const { formula, setFormula } = useFormulaStore();
    const [isError, setIsError] = useState<Record<string, boolean>>({});

    const { mutate } = useCreateFormulaBulk();

    const handleToggleDialog = useCallback(() => {
        setIsVisibleCreateDialog((state) => !state);
    }, []);

    useEffect(() => {
        setFormula(initialValues);
    }, [initialValues]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (body, { setFieldError }) => {
            if (+totalRawWeight > 100) {
                setFieldError(
                    "totalRawWeight",
                    "Total weight percent of rawIngredients must not exceed 100",
                );

                return;
            }

            const toastId = toast.loading(
                toastTexts.loading,
                defaultToastOptions,
            );

            mutate(
                { body },
                {
                    onSuccess(data: unknown) {
                        const { description } = data as {
                            description: string;
                        };
                        setFieldError("description", description);

                        if (!description) {
                            toast.update(toastId, {
                                ...defaultSuccessToastUpdate,
                                render: "Formula has been created",
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.formulas],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.formula],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.rawIngredients],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.subIngredients],
                            });
                            queryClient.invalidateQueries({
                                queryKey: [queryKeys.contaminants],
                            });
                            onClose();
                        } else {
                            toast.update(toastId, defaultErrorToastUpdate);
                        }
                    },
                    onError(error) {
                        const { response } = error as AxiosError<IError>;
                        if (response) {
                            const { data, status } = response;
                            if (status === StatusCode.CONFLICT) {
                                setFieldError("description", data?.message);
                            } else {
                                setFieldError(
                                    "totalRawWeight",
                                    "An error occurred! Please check the provided data and ensure it meets the required format and criteria. " +
                                        data?.message,
                                );
                            }
                        }

                        toast.update(toastId, defaultErrorToastUpdate);
                    },
                },
            );
        },
    });

    const { values, touched, handleChange, handleSubmit, setFieldValue } =
        formik;
    const errors: FormikErrors<IErrors> = formik.errors;

    const calculateTotalRawWeight = () => {
        const value = values.rawIngredients?.reduce((acc, item) => {
            return new BigNumber(acc).plus(item?.weight_percent)?.toNumber();
        }, 0);
        setTotalRawWeight(value);
    };

    useEffect(() => {
        calculateTotalRawWeight();

        return () => {
            setSubmitCount(0);
        };
    }, [values.rawIngredients]);

    useEffect(() => {
        setFieldValue("rawIngredients", formula?.rawIngredients);
    }, [formula]);

    const handleCreateFormula = useCallback((e: FormEvent<HTMLFormElement>) => {
        setSubmitCount((curr) => curr + 1);
        handleSubmit(e);
    }, []);

    return (
        <>
            <FormikProvider value={formik}>
                <form className="relative p-4 " onSubmit={handleCreateFormula}>
                    <div className="py-4 mb-2">
                        <InputWrapper
                            isError={Boolean(
                                errors.description && touched.description,
                            )}
                            error={errors.description}
                            label="Formula Description"
                        >
                            <input
                                type="text"
                                name="description"
                                className={classNames(
                                    "w-full input input-bordered border shadow-lg",
                                    {
                                        "shadow-red-700": Boolean(
                                            errors.description,
                                        ),
                                    },
                                )}
                                value={values.description}
                                onChange={handleChange}
                            />
                        </InputWrapper>
                    </div>
                    <div className="w-full my-8 font-bold text-[#3b82f6]">
                        Included Raw Ingredients
                    </div>
                    <div className="w-full flex justify-start mb-4">
                        <Button
                            text="Add Raw Ingredient"
                            onClick={handleToggleDialog}
                            disabled={totalRawWeight >= 100}
                        />
                    </div>
                    <FieldArray name="rawIngredients">
                        {({ remove }) => (
                            <div className="flex flex-col gap-2">
                                {isVisibleCreateDialog && (
                                    <AddRawIngredient
                                        onClose={handleToggleDialog}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                    />
                                )}

                                {values?.rawIngredients?.map((item, index) => (
                                    <RawIngredientItem
                                        key={`rawIngredient-${index}`}
                                        index={index}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                        item={item}
                                        removeRawIngredient={remove}
                                        setFieldValue={setFieldValue}
                                        isError={isError}
                                        setIsError={setIsError}
                                    />
                                ))}
                            </div>
                        )}
                    </FieldArray>
                    <div className="w-full py-2 text-[#dc2626] flex flex-col gap-1">
                        <span>
                            {Boolean(
                                errors &&
                                    Boolean(Object.keys(errors).length) &&
                                    submitCount &&
                                    !errors.totalRawWeight,
                            ) &&
                                "An error occurred! Please check the provided data and ensure it meets the required format and criteria."}
                        </span>
                        <span>
                            {errors.totalRawWeight && errors.totalRawWeight}
                        </span>
                    </div>
                    <div className="w-full my-4 p-4 flex justify-between items-center">
                        <div className="text-gray-700 font-semibold">
                            Total weight: {totalRawWeight} %
                        </div>
                        <Button
                            type="submit"
                            text="Create Formula"
                            className="py-2 px-4"
                        />
                    </div>
                </form>
            </FormikProvider>
        </>
    );
};
